
import productMixins from "~/mixins/product.js"
export default {
	name: "ProductDetails",
	key: route => "product-page",
	mixins: [productMixins],
	layout: "website",
	data() {
		return {
			afterAddToCartModal: false,
			isAddingToCart: false,
			isBuyingNow: false,
			isGettingBoughtTogetherItems: false,
			boughtTogetherItems: [],
			quantity: 1,
			isSwitchingVariance: false,
			isWishlisting: false,
			selectedVarianceOptionsIds: [],
			ratings: [],
			product: {},
		}
	},

	async fetch() {
		const { productSlug, varianceSlug } = this.$route.params
		await this.$axios
			.$get(`/v1/products/${productSlug}${varianceSlug ? "/" + varianceSlug : ""}`)
			.then((res) => {
				this.product = res
				this.selectedVarianceOptionsIds = this.product.variationAttributes?.map((attribute) => {
					return attribute?.value?.attributeOptionId
				})
			})
			.catch((errors) => {
				if (errors.response.status === 301 && errors.response?.data?.details?.redirectUrl) {
					const path = this.localePath({ name: 'product', params: { productSlug: errors.response?.data?.details?.redirectUrl } })
					// this.$router.replace({ path, query: { status: 301 } })
					// this.$router.push(path)
					if (process.server) {
						this.$nuxt.context.redirect(301, path)
					} else {
						this.$router.replace(path)
					}
				} else {
					this.pageErrorHandler(errors)
				}
			})
	},

	head() {
		return {
			script: [
				{
					type: "application/ld+json",
					innerHTML: JSON.stringify(this.jsonld), // <- set jsonld object in data or wherever you want
				},
			],
			__dangerouslyDisableSanitizers: ["script"], // <- this is important
			titleTemplate: (titleChunk) => {
				const appName = this.$applicationName()
				return titleChunk ? `${titleChunk} | ${appName} ` : appName
			},
			title: this.metaTitle,
			meta: [
				{
					hid: "description",
					name: "description",
					content: this.metaDescription,
				},
				{
					hid: "og:title",
					property: "og:title",
					content: this.metaTitle,
				},
				{
					hid: "og:description",
					property: "og:description",
					content: this.stripTags(this.product?.description),
				},
				{
					hid: "og:image",
					property: "og:image",
					content:
						this.currentVariance?.media?.gallery?.[0]?.src ||
						this.product.media?.cover?.[0]?.src ||
						"/images/product-placeholder.webp",
				},
				{
					hid: "og:url",
					property: "og:url",
					content: this.$route.fullPath,
				},
				{
					hid: "og:type",
					property: "og:type",
					content: "product",
				},
				{
					hid: "og:site_name",
					property: "og:site_name",
					content: "Action.jo",
				},
				{
					hid: "twitter:title",
					name: "twitter:title",
					content: this.metaTitle,
				},
				{
					hid: "twitter:description",
					name: "twitter:description",
					content: this.metaDescription,
				},
				{
					hid: "twitter:image",
					name: "twitter:image",
					content: this.currentVariance?.media?.gallery?.[0] || this.product.media?.covers?.[0],
				},
				{
					hid: "twitter:card",
					name: "twitter:card",
					content: "summary_large_image",
				},
			],
			link: this.generateLinks,
		}
	},

	computed: {
		generateLinks() {
			if (this.$fetchState.pending) { return [] }

			const links = [
				{
					rel: "canonical",
					href: `${process.env.NUXT_ENV_BASE_URL}/${this.$i18n.locale}/product/${this.productSlug}`,
				},
				{
					rel: "alternate",
					hreflang: this.$i18n.locale,
					href: `${process.env.NUXT_ENV_BASE_URL}/${this.$i18n.locale}/product/${this.productSlug}/${this.currentVariance?.slug}`,
				},
			]
			return links
		},
		metaTitle() {
			const varianceSlug = this.varianceSlug
			if (varianceSlug !== undefined) {
				return this.currentVariance?.metaTitle || ''
			}
			return this.product?.metaTitle || ''
		},
		metaDescription() {
			const varianceSlug = this.varianceSlug
			if (varianceSlug !== undefined) {
				return this.currentVariance?.metaDescription || this.stripTags(this.product?.description) || ''
			}
			return this.product?.metaDescription || ''
		},
		varianceSlug() {
			const { varianceSlug } = this.$route.params
			return varianceSlug
		},
		updatedLinks() {
			if (this.product.type === "alternative" && this.product.variances.length > 0) {
				this.product.variances.foreach((value) => {
					// this.product.variance = value;
					if (!this.link[0].href.includes(value.slug.ar)) {
						this.link.push(
							{
								rel: "alternate",
								hreflang: this.$i18n.locale,
								href: `${process.env.NUXT_ENV_BASE_URL}/${this.$i18n.locale}/product/${value.slug}`,
							},
							{
								rel: "alternate",
								hreflang: this.$i18n.locale,
								href: `${process.env.NUXT_ENV_BASE_URL}/${this.$i18n.locale}/product/${value.slug}`,
							},
						).catch(() => { })
					}
				})
			}
			return this.link
		},
		jsonld() {
			const json = {
				"@context": "https://schema.org/",
				"@type": "Product",
				telephone: "+962791009595",
				name: this.title,
				image: this.product?.media?.cover?.[0]?.src || this.product?.media?.gallery?.[0]?.src,
				description: this.stripTags(this.product?.description),
				// mpn: "5607",
				address: {
					"@type": "PostalAddress",
					streetAddress: "buliding No.4 Ibn Al Fata St.,7th circle",
					addressLocality: "Amman",
					addressRegion: "AM",
					postalCode: "11181",
					addressCountry: "JO",
				},
				brand: {
					"@type": "Brand",
					name: this.product?.brand?.name,
				},
				aggregateRating: {
					"@type": "AggregateRating",
					ratingValue: "4",
					reviewCount: "1",
				},
				review: [
					{
						"@type": "Review",
						reviewRating: {
							"@type": "Rating",
							ratingValue: "4",
						},
						author: {
							"@type": "Person",
							name: "Action_5607",
						},
					},
				],
			}

			if (this.isItemAvailable) {
				json.offers = {
					"@type": "Offer",
					priceCurrency: "JOD",
					price: this.isTypeBundle ? this.product?.stock?.price?.value : this.product?.variance?.stock?.price?.value,
					priceValidUntil: "2024-04-20",
					availability: "http://schema.org/InStock",
					seller: {
						"@type": "Organization",
						name: "Action JO",
					},
				}
			}
			return json
		},
		title() {
			return this.product?.name
		},
		hasRatings() {
			return !!this.ratings
		},

		// hasCodPayment(){
		// 	return this.product?.paymentMethods?.find((method) => method.module === "Cod");
		// },
		// hasCreditCardPayment(){
		// 	return this.product?.paymentMethods?.find((method) => method.module === "CreditCard");
		// },

		variancesOptions() {
			if (this.isTypeAlternative) {
				// variancesOptions represents row
				return this.product.variationAttributes.map((attribute) => {
					return {
						...attribute,
						// options: attribute.options.map((option) => {
						// 	option.prefix = attribute.prefix;
						// 	option.suffix = attribute.suffix;

						// 	const attributeOptionsArray = this.generateAttributeOptionsArray(option) || [];
						// 	return {
						// 		...option,
						// 		attributeOptionsArray,
						// 		slug: this.generateOptionSlugFromAttributeOptionsArray(attributeOptionsArray),
						// 	};
						// }),
					}
				})
			}
			return null
		},

		discount() {
			//	updated it with "?" to recover if the object/value is null
			if (this.isTypeBundle && this?.stock?.isOffer) {
				return {
					value: this.stock.priceBeforeOffer?.value - this.stock.price?.value,
					currency: this.stock.price.currency,
				}
			}
			if (this.currentVariance?.stock?.isOffer) {
				return {
					value: this.currentVariance.stock.priceBeforeOffer?.value - this.currentVariance.stock.price?.value,
					currency: this.currentVariance.stock.price.currency,
				}
			}

			return null
		},

		isPreOrder() {
			return this.stock?.isPreOrder ?? false
		},
		notePreOrder() {
			if (this.isTypeBundle) {
				return !!this.product.stock?.note ?? ''
			}
			return this.currentVariance?.stock?.note ?? ''
		},
		breadcrumbItems() {
			return [
				{
					text: "Home",
					disabled: false,
					to: this.localePath({
						name: "index",
					}),
					link: true,
					nuxt: true,
					exactPath: true,
				},

				{
					text: this.title,
					disabled: true,
				},
			]
		},
		isTypeSimple() {
			return this.product.type === "simple"
		},
		isTypeBundle() {
			return this.product.type === "bundle"
		},
		isTypeAlternative() {
			return this.product.type === "alternative"
		},
		currentVariance() {
			// if (!this.isTypeAlternative) return null;
			// if (!this.$route.params.varianceSlug) {
			return this.product.variance
			// }
			// return this.product.alternatives.find((alternative) => alternative.slug === this.$route.params.varianceSlug);
		},
		productSlug() {
			return this.product.slug
		},
		gallery() {
			const arr = []
			if (this.currentVariance?.media?.gallery) {
				arr.push(...this.currentVariance?.media?.gallery)
			}
			if (this.product?.media?.gallery) {
				arr.push(...this.product?.media?.gallery)
			}
			return arr
		},
		isItemAvailable() {
			if (this.isTypeBundle) {
				return this.stock?.quantity && this.stock.quantity > 0
			}
			return this.currentVariance?.stock?.quantity && this.currentVariance.stock.quantity > 0
		},
		combinedAttributes() {
			try {
				if (this.isTypeBundle) {
					const attributes = this.product.bundles.flatMap((bundle) => {
						return bundle.attributes.map((attribute) => {
							return attribute
						})
					})
					return attributes
				} else {
					return [...this.currentVariance?.attributes, ...this.product?.attributes]
				}
			} catch (e) {
				// console.log(this.currentVariance, this.product);
				return []
			}
		},
		stock() {
			if (this.isTypeBundle) {
				return this.product.stock
			}
			if (this.isTypeAlternative || this.isTypeSimple) {
				return this.currentVariance?.stock
			}

			return false
		},
		hasStock() {
			return !!this.stock && this.stock.quantity > 0
		},
		hasDiscount() {
			if (this.hasStock) {
				return this.stock.isOffer
			}

			return false
		},
		discountExpiresIn() {
			if (this.hasDiscount) {
				return this.stock.unPublishedAt
			}
			return 0
		},
	},
	methods: {
		fullOptionSlug(newOptionSlug, oldOptionSlug) {
			// const currentSlug = this.currentVariance.slug;
			// const newSlug = currentSlug.replace(oldOptionSlug, newOptionSlug);

			// if (newSlug === currentSlug) {
			// 	console.error("newSlug === currentSlug", newOptionSlug, oldOptionSlug, currentSlug, newSlug);
			// 	return currentSlug;
			// }

			// // check if newSlug is valid
			// const newAlternative = this.product.alternatives.find((alternative) => alternative.slug === newSlug);
			// if (newAlternative) {
			// 	return newSlug;
			// }
			return "currentSlug"
		},
		addToCart() {
			if (!this.isItemAvailable) { return }
			this.isAddingToCart = true
			this.isGettingBoughtTogetherItems = true
			this.$store
				.dispatch("cart/add", {
					name: this.title,
					sku: !this.isTypeBundle ? this.currentVariance.SKU : null,
					media: !this.isTypeBundle ? this.currentVariance?.media : this.product?.media,
					productId: this.product.productId,
					varianceId: !this.isTypeBundle ? this.currentVariance.varianceId : null,
					quantity: this.quantity,
					price: !this.isTypeBundle ? this.currentVariance.stock.price : this.product.stock.price,
				})
				.then(() => {
					this.afterAddToCartModal = true
					this.getBoughtTogetherItems()
					this.$fb.track('AddToCart', {
						content_ids: [this.product.productId],
						content_name: this.title,
						content_type: 'product',
						value: this.currentVariance.stock.price.value,
						currency: this.currentVariance.stock.price.currency,
						quantity: this.quantity,
					})
				})
				.catch(() => {
					this.$toast.error("Couldn't add to cart right now. Please try again later.")
				})
				.finally(() => {
					this.isAddingToCart = false
				})

			// this.$store
			// 	.dispatch("cart/add", {
			// 		name: this.title,
			// 		sku: this.currentVariance.SKU,
			// 		media: image,
			// 		image,
			// 		productId: this.product.productId,
			// 		varianceId: this.currentVariance.varianceId,
			// 		quantity: this.quantity,
			// 		price: this.currentVariance.stock.price,
			// 	})
			// 	.then(() => {
			// 		this.afterAddToCartModal = true;
			// 	})
			// 	.catch(() => {
			// 		this.$toast.error("Couldn't add to cart right now. Please try again later.");
			// 	})
			// 	.finally(() => {
			// 		this.isAddingToCart = false;
			// 	});
		},
		incrementQuantity() {
			if (this.quantity < this.currentVariance.stock.quantity) {
				this.quantity++
			}
		},
		decrementQuantity() {
			if (this.quantity > 1) {
				this.quantity--
			}
		},
		async getBoughtTogetherItems() {
			this.isGettingBoughtTogetherItems = true
			try {
				const resp = await this.$axios.$get(`/v1/suggested-product/${this.product.productId}`)
				console.log("resp", resp)
				this.boughtTogetherItems = resp
				console.log("boughtTogetherItems", this.boughtTogetherItems)
			} catch (error) {
				console.error("Error fetching suggested products:", error)
				// Handle the error, such as displaying an error message to the user
			} finally {
				this.isGettingBoughtTogetherItems = false
			}
			// this.isGettingBoughtTogetherItems = true;

			// this.$axios
			// 	.$get(`/v1/suggested-product/${this.product.productId}`)
			// 	.then((resp) => {
			// 		this.boughtTogetherItems = resp.items;

			// 		console.log("boughtTogetherItems", this.boughtTogetherItems);
			// 	})
			// 	.finally(() => {
			// 		this.isGettingBoughtTogetherItems = false;
			// 	});
		},

		viewCart() {
			this.afterAddToCartModal = false
			this.$nuxt.$emit("cart.show")
		},
		buyNow() {
			this.isBuyingNow = true
			this.$axios
				.$post("/v1/orders/buy-now", {
					productId: this.product.productId,
					varianceId: this.product.type === "bundle" ? null : this.currentVariance.varianceId,
					quantity: this.quantity,
				})
				.then((resp) => {
					this.$router.push(
						this.localePath({
							name: "checkout",
							params: {
								orderId: resp.orderId,
								step: 1,
							},
						}),
					)
				})
				.finally(() => {
					this.isBuyingNow = false
				})
		},
		replaceSelectedVarianceOptionId(attributeOptionId, newOptionId) {
			// console.log("replaceSelectedVarianceOptionId");
			// console.log(attributeOptionId, newOptionId);
			const index = this.selectedVarianceOptionsIds.indexOf(attributeOptionId)
			if (index > -1) {
				this.selectedVarianceOptionsIds.splice(index, 1, newOptionId)
			}
			// console.log(this.selectedVarianceOptionsIds);
		},
		switchVariance() {
			this.isSwitchingVariance = true

			// get all selected options by looping through all attribute options buttons and get the value of the active one
			const attributesOptions = this.selectedVarianceOptionsIds // this.$refs.attributeOption.filter((option) => option.isActive).map((option) => option.value);
			// let product;
			this.$axios
				.$post("/v1/products/" + this.product.slug, {
					productId: this.product.productId,
					attributesOptions,
				})
				.then(async (resp) => {
					await this.$router.replace({
						...this.$router.currentRoute,
						params: {
							productSlug: resp.slug,
							varianceSlug: resp.variance?.slug,
						},
					})

					this.product = resp
					this.selectedVarianceOptionsIds = this.product.variationAttributes.map((attribute) => {
						return attribute.value.attributeOptionId
					})
				})
				.finally(() => {
					this.isSwitchingVariance = false
				})
		},
		toggleWishlist() {
			this.isWishlisting = true
			this.$store.dispatch("wishlist/toggle", this.product).finally(() => {
				this.isWishlisting = false
			})
		},
	},
}
